const state = () => ({
  locale: 'en',
  partner: {
    id: 1,
    name: 'POSBytz',
    slug: 'posbytz',
    logo_dark: '',
    domain: 'https://posbytz.com'
  },
  isLoggedIn: false,
  loading: false,
  onDuty: true,
  orderInProgress: false,
  currentOrderProgressStatus: '',
  userInfo: null,
  policy: null,
  merchantInfo: null,
  handelRunnerLocationUpdate: null,
  locationInterval: 30000 // 30 seconds
})

export default state
